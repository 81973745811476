<template lang="pug">
//- disposal
div(class="disposal-section")
    Spacer
    //- section header
    Container
        div(class="d-flex")
            img(
                src="@/assets/icon/dotted-arrow-1.png"
                height="35"
                class="mr-4 mt-2"
            )
            div
                IonText(
                    color="secondary"
                )
                    h1 Materialdispo
                IonText(
                    color="tertiary"
                )
                    div(class="mt-1") Heute {{ todayDate }} - zum Auftrag mitnehmen

    Spacer

    //- table headers
    IonRow(class="table-headers")
        IonCol(
            size="2"
        )
            IonText(
                color="secondary"
            )
                h4(class="ion-padding-start")
                    div Art. Numm.

        IonCol(
            size="4"
        )
            IonText(
                color="secondary"
            )
                h4 Artikel
        IonCol(
            size="2"
        )
            IonText(
                color="secondary"
            )
                h4(class="ion-text-center") Lagerort
        IonCol(
            size="2"
        )
            IonText(
                color="secondary"
            )
                h4(class="ion-text-center") Menge
        IonCol(
            size="2"
            class="ion-padding-end"
        )
            IonText

    //- table rows
    DisposalItem(
        v-for="(item, index) in nextUncompletedOrder.lines"
        :key="index"
        v-if="nextUncompletedOrder"
        :disposalItem="item"
        style="margin-bottom: 2px"
        v-model="disposalItemsCheckMarks[index]"
    )

//- orders
div(class="orders-section")
    Spacer
    Spacer
    //- section header
    Container
        IonText(
            color="secondary"
        )
            h1 Auftragsübersicht
        IonText(
            color="tertiary"
        )
            div Am {{ todayDate }}

    Spacer

    //- completed orders list
    div(
        v-if="completedOrders?.length"
    )
        //- Container
        ExpansionPanel(
            v-model="expansionPanelOpened"
            header="Erledigte Aufgaben"
            no-margin
        )
            OrderItem(
                v-for="(order, index) in completedOrders"
                :key="index"
                :order="order"
                style="margin-bottom: 2px"
            )
        Spacer
    //- uncompleted orders list
    OrderItem(
        v-for="(order, index) in uncompletedOrders"
        :key="index"
        :order="order"
        style="margin-bottom: 2px"
        :expanded="order?.id == nextUncompletedOrder?.id && allChecked"
    )
</template>

<script>
import DisposalItem from "@/components/molecules/DisposalItem"
import OrderItem from "@/components/molecules/OrderItem"
import ExpansionPanel from "@/components/molecules/ExpansionPanel"
import { useState } from "@/composables/state.js"
import { ref, onMounted, computed, watch } from "vue"
import moment from "moment"
export default {
    components: {
        DisposalItem,
        OrderItem,
        ExpansionPanel
    },
    props: {
        orders: {
            required: true,
            type: Array
        }
    },

    setup() {
        const { nextUncompletedOrder, completedOrders, uncompletedOrders } =
            useState()

        const expansionPanelOpened = ref(false)

        // makes correct number of elements
        const disposalItemsCheckMarks = ref()
        if (nextUncompletedOrder.value)
            disposalItemsCheckMarks.value =
                nextUncompletedOrder.value.lines.map(() => false)

        // onMounted(() => console.log(disposalItemsCheckMarks.value))

        const allChecked = computed(() => {
            return disposalItemsCheckMarks.value.every((a) => {
                return a
            })
        })

        const todayDate = moment().format("DD.MM.YYYY")

        return {
            nextUncompletedOrder,
            uncompletedOrders,
            disposalItemsCheckMarks,
            allChecked,
            todayDate,
            completedOrders,
            expansionPanelOpened
        }
    }
}
</script>

<style lang="sass" scoped>
.disposal-section
    .table-headers
        padding-bottom: 5px
        ion-col
            padding-left: 0
</style>
