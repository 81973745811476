<template lang="pug">
BaseLayout
    DisposalAndOrders(
        :orders="orders"
    )
</template>

<script>
import { useState } from "@/composables/state.js"
import DisposalAndOrders from "@/components/sections/DisposalAndOrders"
export default {
    setup() {
        const { orders } = useState()

        return { orders }
    },
    components: {
        DisposalAndOrders
    }
}
</script>