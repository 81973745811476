<template lang="pug">
div(class="disposal-item")
    IonRow(
        style="width: 100%"
    )
        IonCol(
            size="2"
        )
            IonText(class="ion-padding-start")
                h3 {{ disposalItem.id }}
        IonCol(
            size="4"
            style="word-break: break-all"
        )
            IonText
                h3 {{ disposalItem.positiontext }}
        IonCol(
            size="2"
            class="ion-justify-content-center"
        )
            IonText {{ disposalItem.storage }}
        IonCol(
            size="2"
            class="ion-justify-content-center"
        )
            IonText {{ disposalItem.quantity }}
        IonCol(
            size="2"
            class="ion-justify-content-center"
            @click="checked = !checked"
        )
            CustomCheckableIcon(
                :checked="checked"
            )
</template>

<script>
import CustomCheckableIcon from "@/components/molecules/CustomCheckableIcon"
import { ref, watch } from "vue"
export default {
    components: { CustomCheckableIcon },
    props: {
        disposalItem: {
            required: true,
            type: Object
        },
        modelValue: Boolean
    },
    setup(props, ctx) {
        const checked = ref(false)

        watch(checked, (val) => {
            ctx.emit(`update:modelValue`, val)
        })

        watch(
            () => props.modelValue,
            (val) => {
                checked.value = val
            },
            { immediate: true }
        )

        return { checked }
    }
}
</script>

<style lang="sass" scoped>
.disposal-item
    width: 100%
    ion-row
        ion-col
            padding-left: 0
            padding-top: 10px
            padding-bottom: 10px
            padding-right: 0
            display: flex
            align-items: center
            background-color: var(--ion-color-tertiary)
        :nth-of-type(4)
            border-radius: 0 8px 8px 0
        :nth-of-type(5)
            border-radius: 8px 0 0 8px
            background-color: var(--ion-color-primary-tint)
</style>