<template lang="pug">
div(class="order-item")
    IonRow(
        style="width: 100%"
    )
        IonCol(
            size="10"
            class="item-content pt-3 pb-3 overflow-hidden radius-right d-flex align-start"
        )
            IonText(class="ion-padding-start")
                h3(class="pb-2") {{ order.id }} {{ order.cause }}
                div(class="small") {{ order.comment }}
        IonCol(
            size="2"
            class="overflow-hidden ion-justify-content-center ion-no-padding radius-left d-flex align-center justify-center"
            style="background-color: var(--ion-color-primary); min-height: 65px"
        )
            CustomIcon(
                fileName="check.png"
                size="45"
                v-if="order.completed != 0"
            )
            IonIcon(
                v-else
                :icon="chevronDownCircle"
                :color="expanded ? 'secondary' : 'tertiary'"
                size="large"
            )

    div(
        class="panel d-flex ion-align-items-center ion-justify-content-between"
        :class="expanded ? 'expanded pt-2 pb-2' : ''"
    )
        div(class="ion-padding-start")
            div(
                v-if="!!order.locationstreet"
            ) {{ order.locationstreet }}
            div(class="d-flex align-center")
                div(
                    v-if="!!order.locationzipcode"
                    class="mr-1"
                ) {{ order.locationzipcode }}
                div(
                    v-if="!!order.locationcity"
                ) {{ order.locationcity }}
        IonButton(
            color="secondary"
            fill="solid"
            @click="$router.push({ name: 'order-1', params: { id: order.id } })"
            size="large"
        )
            IonText(
                color="primary"
            )
                h3
                    div Auftrag
                    div Starten
            //- IonIcon(
            //-     slot="end"
            //-     :icon="arrowForwardOutline"
            //-     color="primary"
            //- )
            img(
                slot="end"
                src="@/assets/icon/dotted-arrow-2.png"
                height="13"
                class="ml-2"
            )
</template>

<script>
import { chevronDownCircle, arrowForwardOutline } from "ionicons/icons"
import CustomIcon from "@/components/molecules/CustomIcon"
export default {
    components: {
        CustomIcon
    },

    data: () => ({
        chevronDownCircle,
        arrowForwardOutline
    }),
    props: {
        order: {
            required: true,
            type: Object
        },
        expanded: Boolean
    }
}
</script>

<style lang="sass">
.order-item
    ion-row
        ion-col
            padding-left: 0
            background-color: var(--ion-color-accent)
            &.item-content
                position: relative

                &::before
                    display: block
                    content: ''
                    position: absolute
                    top: 0
                    left: 0
                    width: 4px
                    height: 100%
                    background-color: var(--ion-color-tertiary)
                &::after
                    display: block
                    content: ''
                    position: absolute
                    top: 15px
                    left: -3px
                    width: 10px
                    height: 10px
                    background-color: var(--ion-color-tertiary)
                    transform: rotate(45deg)

    .panel
        height: 0
        overflow: hidden
        // transition: .2s all ease-in-out
        // transform: scaleY(0)
        transform-origin: top
        background-color: var(--ion-color-quaternary)
        color: var(--ion-color-accent)
        &.expanded
            // transform: scaleY(1)
            height: auto
        ion-button
            --border-radius: 8px 0 0 8px
</style>