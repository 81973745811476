<template lang="pug">
div(
    class="expansion-panel "
    style="width: 100%"
    :class="{ expanded: expanded }"
)
    div(
        class="d-flex align-center justify-space-between ion-padding-end ion-padding-start"
    )
        div
            h3(
                class="mr-5"
                v-if="header"
            ) {{ header }}
        IonButton(
            style="width: 30px; height: 30px; --padding-start: 0; --padding-end: 0; margin: 0"
            class="round-btn"
            @click="handleClick"
        )
            //- expand="full"
            CustomIcon(
                fileName="expand.png"
                class="arrow-icon"
            )
    //- //- header
    //- IonButton(
    //-     style="width: 100%; height: 60px; --padding-start: 0; --padding-end: 0; margin: 0"
    //-     expand="full"
    //-     color="accent"
    //-     @click="handleClick"
    //- )
    //-     div(
    //-         style="width: 100%; height: 100%"
    //-         class="item-content d-flex align-center justify-end"
    //-     )
    //-         h3(class="mr-5") {{ label }}

    //-         div(
    //-             style="height: 100%; width: 50px"
    //-             class="radius-right overflow-hidden d-flex align-center justify-center"
    //-         )
    //-             div(class="arrow-icon d-flex align-center justify-center")
    //-                 //- IonIcon(
    //-                 //-     :icon="chevronForwardCircle"
    //-                 //-     color="secondary"
    //-                 //-     style="font-size: 22px"
    //-                 //- )
    //-                 CustomIcon(
    //-                     fileName="expand.png"
    //-                 )

    //- content
    div(
        class="content-wrapper"
        ref="wrapper"
        :class="!noMargin ? 'ion-padding-end ion-padding-start' : ''"
    )
        div(
            ref="content"
        )
            slot
</template>

<script>
import { chevronForwardCircle } from "ionicons/icons"
import { watch, ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import CustomIcon from "@/components/molecules/CustomIcon"
export default {
    components: { CustomIcon },
    watch: {
        expanded(val) {
            this.$refs.wrapper.style.height =
                this.$refs.content.offsetHeight + "px"
            if (val) {
                setTimeout(() => {
                    this.$refs.wrapper.style.height = "auto"
                }, 250)
            } else {
                setTimeout(() => {
                    this.$refs.wrapper.style.height = 0
                }, 10)
            }
        }
    },

    props: {
        modelValue: Boolean,
        header: String,
        noMargin: Boolean
    },

    setup(props, { emit }) {
        chevronForwardCircle

        const expanded = ref(false)

        watch(expanded, (val) => {
            emit("update:modelValue", val)
        })

        watch(
            () => props.modelValue,
            (val) => {
                expanded.value = val
            },
            { immediate: true }
        )

        function handleClick() {
            expanded.value = !expanded.value
        }

        return {
            expanded,
            chevronForwardCircle,
            handleClick
        }
    }
}
</script>

<style lang="sass" scoped>
.expansion-panel
    .content-wrapper
        overflow: hidden
        height: 0px
        transition: all .2s ease-in-out
        >div
            // padding: 0px 25px 30px

    .arrow-icon
        -webkit-transition: -webkit-transform .2s ease-in-out
        -ms-transition: -ms-transform .2s ease-in-out
        transition: transform .2s ease-in-out
    &.expanded
        .arrow-icon
            transform: rotate(180deg)
            -ms-transform: rotate(180deg)
            -webkit-transform: rotate(180deg)




</style>